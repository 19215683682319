<template>
</template>

<script>
import utils from "@/services/utils";

export default {
  name: "CheckService",
  created: function () {
    var user = this.$store.state.auth.user_info.user;
    switch (user.service_type) {
      case 'btfs':
        // this.$router.push('/btfs')
        this.$router.push({name: 'BTFSBoard'});
        break;
      case 'thcs':
        this.$router.push({name: 'THCSBoard'});
        break;
      case 'codeg':
        this.$router.push({name: 'CODEGBoard'});
        break;
      case 'military':
        this.$router.push({name: 'MilitaryBoard'});
        break;              
      case 'watek':
        this.$router.push({name: 'WATEKBoard'});
        break;        
      case 'srds':
        this.$router.push({name: 'SRDSBoard'});
        break;
      case 'door':
        this.$router.push({name: 'DoorBoard'});
        break;        
      case 'fumax':
        this.$router.push({name: 'FumaxBoard'});
        break;
      case 'snow':
        this.$router.push({name: 'FumaxSnowBoard'});
        break;    
      case 'swfm':
        this.$router.push({name: 'SWFMBoard'});
        break;        
      case 'pwdm':
        this.$router.push({name: 'PWDMBoard'});
        break;                  
      case 'sis':
      default:
        this.$router.push({name: 'Dashboard'});
    }
  },
};
</script>
